<template>
  <b-card v-if="status" :title="`${$t('edit')}`">
    <form
      @submit.prevent="updateStatus"
      @keydown="form.onKeydown($event)"
      class="edit-form"
    >
      <b-row>
        <b-col sm="6">
          <div class="form-group">
            <label for="name"
              >{{ $t("name") }}<span class="text-danger">*</span></label
            >
            <input
              type="text"
              name="name"
              class="form-control"
              v-model="form.name"
              :class="{ 'is-invalid': form.errors.has('name') }"
            />
            <has-error :form="form" field="name" />
          </div>
          <div class="form-group">
            <label for="type"
              >{{ $t("type") }}<span class="text-danger">*</span></label
            >
            <select v-model="form.type" class="form-control">
              <option value="order">{{ $t("order") }}</option>
              <option value="payment">{{ $t("payment") }}</option>
              <option value="delivery">{{ $t("delivery") }}</option>
            </select>
            <has-error :form="form" field="type" />
          </div>
          <div class="form-group">
            <label for="type"
              >{{ $t("default_selected")
              }}<span class="text-danger">*</span></label
            >
            <select v-model="form.selected" class="form-control">
              <option :value="true">{{ $t("selected") }}</option>
              <option :value="false">{{ $t("deselected") }}</option>
            </select>
            <has-error :form="form" field="type" />
          </div>
        </b-col>
        <b-col sm="6">
          <h5>{{ $t("show_for") }}:</h5>
          <div
            v-for="item in deliveries"
            class="custom-control custom-checkbox"
            :key="item.id"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`checkbox-${item.id}`"
              v-model="form.show_for"
              :value="item.id"
            />
            <label class="custom-control-label" :for="`checkbox-${item.id}`">{{
              item.name
            }}</label>
          </div>
          <has-error :form="form" field="show_for" />
          <hr />
          <h5>{{ $t("show_for_statuses") }}:</h5>
          <div
            v-for="payment in payments"
            class="custom-control custom-checkbox"
            :key="payment.id + 11"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`checkbox-p-${payment.id}`"
              v-model="form.show_for_payments"
              :value="payment.id"
            />
            <label
              class="custom-control-label"
              :for="`checkbox-p-${payment.id}`"
              >{{ payment.name }}</label
            >
          </div>
          <has-error :form="form" field="show_for_payments" />
        </b-col>
      </b-row>
      <button type="submit" class="btn btn-success" :disabled="form.busy">
        {{ $t("edit") }}
      </button>
    </form>
    <b-row v-if="form.type === 'delivery'">
      <b-col sm="12">
        <div class="text-right mb-2">
          <b-button variant="outline-primary" size="sm" id="show-btn" @click="addNewStatus">{{ $t('add') }}</b-button>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th>Тип доставки</th>
              <th class="text-center">{{ $t('params') }}</th>
              <th>Статус</th>
              <th>Не відслідковуєм</th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="deliveryStatuses">
            <tr v-for="(item ,index) in deliveryStatuses" :key="index">
              <td>{{ item.delivery_type }}</td>
              <td class="text-center">
                <ul v-if="item.params && item.params.length > 0" class="list-unstyled">
                  <li v-for="(s, i) in item.params" :key="i">
                    {{ $t('params') }}: <strong>{{ s.param }}</strong><br/>
                    {{ $t('value') }}: <strong>{{ s.value }}</strong><br/>
                    {{ $t('entry') }}: <strong> <span v-if="s.entry">{{ $t('yes') }}</span><span v-else>{{ $t('no') }}</span></strong><br/>
                    {{ $t('missing_param') }}: <strong> <span v-if="s.missingParam">{{ $t('yes') }}</span><span v-else>{{ $t('no') }}</span></strong>
                  </li>
                </ul>
              </td>
              <td>{{ item.name }}</td>
              <td>
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" :id="'trackingCompleteAtStatus-' + item.id" v-model="item.tracking_complete_at_status" @change="trackingComplete(item.id, $event)">
                  <label class="custom-control-label" :for="'trackingCompleteAtStatus-' + item.id">Через {{ item.tracking_complete_at }} днів</label>
                </div>
              </td>
              <td class="text-right">
                <b-button-group>
                  <b-button  @click="info(item, index, $event.target)" variant="warning" size="sm">
                    <fa icon="pencil-alt" />
                  </b-button>
                  <b-button @click="removeItem(item.id)" variant="danger" size="sm">
                    <fa icon="minus" />
                  </b-button>
                </b-button-group>
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
    <b-modal id="statuses-modal" hide-footer size="lg">
    <form @submit.prevent="addStatus" @keydown="modalForm.onKeydown($event)" class="create-form">
      <div class="form-group">
        <label for="">{{ $t('name') }}</label>
        <input type="text" name="name" class="form-control form-control-sm" v-model="modalForm.name" :class="{ 'is-invalid': modalForm.errors.has('name') }" :placeholder="$t('name')">
        <has-error :form="modalForm" field="name" />
      </div>
      <!--<div class="form-group">
        <label for="">{{ $t('code') }}</label>
        <input type="text" name="code" class="form-control form-control-sm" v-model="modalForm.code" :class="{ 'is-invalid': modalForm.errors.has('name') }" :placeholder="$t('code')">
        <has-error :form="modalForm" field="code" />
      </div>-->
      <!-- -->
      <div class="card">
        <div class="card-body">
          <div class="form-group">
            <label for="params">{{ $t('params') }}</label>
            <input type="text" name="params" class="form-control form-control-sm" v-model="params.param" :placeholder="$t('params')">
          </div>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" name="missingParam" id="missingParam" class="custom-control-input" v-model="params.missingParam" :placeholder="$t('missing_param')">
            <label for="missingParam" class="custom-control-label">{{ $t('missing_param') }}</label>
          </div>
          <div class="form-group">
            <label for="value">{{ $t('value') }}</label>
            <input type="text" name="value" class="form-control form-control-sm" v-model="params.value" :placeholder="$t('value')" :disabled="params.missingParam">
          </div>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" name="entry" id="entry" class="custom-control-input" v-model="params.entry" :placeholder="$t('entry')" value="true">
            <label for="entry" class="custom-control-label">{{ $t('entry') }}</label>
          </div>
          <button class="btn btn-sm btn-success" type="button" @click="addParams"><fa icon="plus" /></button>
          <hr>
          <table v-if="modalForm.params" class="table">
            <thead>
            <tr>
              <th>{{ $t('params') }}</th>
              <th>{{ $t('value') }}</th>
              <th class="text-center">{{ $t('entry') }}</th>
              <th class="text-center">{{ $t('missing_param') }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, idx) in modalForm.params" :key="idx">
              <td>{{ item.param }}</td>
              <td>{{ item.value }}</td>
              <td class="text-center">
                <span v-if="item.entry">{{ $t('yes') }}</span>
                <span v-else>{{ $t('no') }}</span>
              </td>
              <td class="text-center">
                <span v-if="item.missingParam">{{ $t('yes') }}</span>
                <span v-else>{{ $t('no') }}</span>
              </td>
              <td>
                <b-button variant="danger" size="sm" @click="removeParam(idx)"><fa icon="times"/></b-button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- / -->
      <div class="form-group">
        <label for="">{{ $t('delivery_type') }}</label>
        <select class="form-control" v-model="modalForm.delivery_id" :class="{ 'is-invalid': modalForm.errors.has('delivery_id') }">
          <option v-for="item in deliveries" :key="item.id" :value="item.id">{{ item.name }}</option>
          <has-error :form="modalForm" field="delivery_id" />
        </select>
      </div>
      <div class="form-group">
        <label for="">{{ $t('order_status') }}</label>
        <select class="form-control" v-model="modalForm.order_status_id" :class="{ 'is-invalid': modalForm.errors.has('order_status_id') }">
          <option v-for="item in statuses" :key="item.id" :value="item.id">{{ item.name }}</option>
          <has-error :form="modalForm" field="order_status_id" />
        </select>
      </div>
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="trackingCompleteAtStatus" :class="{ 'is-invalid': modalForm.errors.has('tracking_complete_at_status') }" v-model="modalForm.tracking_complete_at_status">
        <label class="custom-control-label" for="trackingCompleteAtStatus">{{ $t('tracking_complete_at_status') }}</label>
        <has-error :form="modalForm" field="tracking_complete_at_status" />
      </div>
      <div v-if="modalForm.tracking_complete_at_status" class="form-group">
        <input type="text" name="trackingCompleteAt" :class="{ 'is-invalid': modalForm.errors.has('tracking_complete_at') }" class="form-control" v-model="modalForm.tracking_complete_at">
        <has-error :form="modalForm" field="tracking_complete_at" />
      </div>
      <button type="submit" class="btn btn-success btn-sm" :disabled="modalForm.busy">{{ modalForm.id === '' ? $t('add') : $t('edit') }}</button>
    </form>
  </b-modal>
  </b-card>
</template>
<script>
import Form from 'vform'
import axios from 'axios'

export default {
  middleware: 'auth',
  layout: 'default',
  metaInfo () {
    return { title: this.$t('edit') }
  },
  data: () => ({
    form: new Form({
      name: '',
      type: '',
      selected: false,
      show_for: [],
      show_for_payments: []
    }),
    modalForm: new Form({
      id: '',
      name: '',
      delivery_id: '',
      order_status_id: '',
      params: [],
      tracking_complete_at_status: false,
      tracking_complete_at: 0
    }),
    params: {
      param: '',
      value: '',
      entry: false,
      missingParam: false
    },
    status: null,
    deliveries: null,
    payments: null,
    deliveryStatuses: null,
    statusModal: {
      id: 'statuses-modal',
      title: '',
      content: ''
    },
    statuses: null
  }),
  mounted () {
    this.fetchData()
    this.fetchDeliveries()
    this.fetchPayments()
    this.fetchDeliveryStatuses()
    this.fetchStatuses()
  },
  methods: {
    async updateStatus () {
      this.form.busy = true
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      const id = this.$route.params.id
      await this.form
        .put(apiUrl + 'statuses/' + id)
        .then((resp) => {
          this.$router.push({ name: 'statuses' })
        })
        .catch((error) => {
          this.form.errors.set(error.response.data.error.errors)
        })
    },
    async fetchData () {
      const id = this.$route.params.id
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      const { data } = await axios.get(apiUrl + 'statuses/' + id)
      this.form.fill(data.data)
      this.status = data.data
    },
    async fetchDeliveries () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      const { data } = await axios.get(apiUrl + 'deliveries')
      this.deliveries = data.data
    },
    async fetchPayments () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      const { data } = await axios.get(apiUrl + 'payments')
      this.payments = data.data
    },
    async fetchDeliveryStatuses () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      const id = this.$route.params.id
      const { data } = await axios.get(apiUrl + 'delivery-statuses-crm/status/' + id)
      this.deliveryStatuses = data
    },
    addStatus () {
      if (this.modalForm.id !== '') {
        this.updateStatusCrm()
      } else {
        this.createDeliveryStatus()
      }
    },
    async createDeliveryStatus () {
      this.modalForm.busy = true
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      this.modalForm.status_id = this.$route.params.id
      await this.modalForm.post(apiUrl + 'delivery-statuses-crm').then(resp => {
        // this.$router.push({ name: 'deliveries.statuses' })
        this.fetchDeliveryStatuses()
        this.$root.$emit('bv::hide::modal', this.statusModal.id)
        this.modalForm.reset()
      }).catch(error => {
        this.modalForm.errors.set(error.response.data.error.errors)
      })
    },
    async updateStatusCrm () {
      this.modalForm.busy = true
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      const id = this.$route.params.id
      await this.modalForm.put(apiUrl + 'delivery-statuses-crm/' + id).then(resp => {
        this.fetchDeliveryStatuses()
        this.$root.$emit('bv::hide::modal', this.statusModal.id)
        this.modalForm.reset()
      }).catch(error => {
        this.modalForm.errors.set(error.response.data.error.errors)
      })
    },
    info (item, index, btn) {
      this.modalForm.fill(item)
      this.$root.$emit('bv::show::modal', this.statusModal.id, btn)
    },
    addNewStatus () {
      this.$bvModal.show('statuses-modal')
      this.modalForm.reset()
    },
    async removeItem (id) {
      this.$root.$refs.loading.start()
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      const result = confirm(this.$t('want_to_delete'))
      if (result) {
        await axios.delete(apiUrl + 'delivery-statuses-crm/' + id)
        this.fetchDeliveryStatuses()
        this.$root.$refs.loading.finish()
      }
    },
    async fetchStatuses () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      const { data } = await axios.get(apiUrl + 'statuses')
      this.statuses = data.data.filter(s => s.type === 'order')
    },
    addParams () {
      this.modalForm.params.push({
        param: this.params.param,
        value: this.params.value,
        entry: this.params.entry,
        missingParam: this.params.missingParam
      })
    },
    async trackingComplete (id, e) {
      const val = e.target.checked
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'delivery-statuses-crm/tracking', { statusId: id, checked: val }).then(resp => {
        if (resp.data && resp.data.status) {
          this.fetchDeliveryStatuses()
        }
      })
    },
    removeParam (index) {
      this.modalForm.params.splice(index, 1)
    }
  }
}
</script>
